import React from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";
import { InputGroupText } from "reactstrap";



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//style csss
import "../css/style.css";


// Here, we display our Navbar
export default function Footer() {
    return (
        <footer id="Footer" className="clearfix">
            <div className="widgets_wrapper">
                <div className="container">
                    <div className="column one-third">
                        <aside id="text-2" className="widget widget_text">
                            <div className="textwidget">
                                <div className="footer-logo">
                                    <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                                        <div className="image_wrapper">
                                            <img className="scale-with-grid" src="/images/yava-logo-white-1.png" alt="yava-logo" title="yava-logo-white-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                    <div className="column one-third">
                        <aside id="text-10" className="widget widget_text">
                            <div className="textwidget">
                                <ul>
                                    <li>
                                        <a href="https://www.mcaleadsonline.com/#Header_wrapper">Home</a>
                                    </li>
                                    {/* <li>
                                        <a href="https://leads.mcaleadsonline.com/plans">Pricing</a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.mcaleadsonline.com/#whyyavaleads2biz">Why MCA Leads Online</a>
                                    </li>
                                    <li>
                                        <a href="https://www.mcaleadsonline.com/#benefits">Benefits</a>
                                    </li>
                                    <li>
                                        <a href="https://www.mcaleadsonline.com/#faq">FAQ</a>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                    <div className="column one-third">
                        <aside id="custom_html-2" className="widget_text widget widget_custom_html">
                            <div className="textwidget custom-html-widget">
                                <div>
                                    <ul>
                                        {/* <li>
                                            <a href="https://www.mcaleadsonline.com/#faq">FAQ</a>
                                        </li> */}
                                        <li>
                                            <a href="https://www.mcaleadsonline.com/#contactus">Contact Us</a>
                                        </li>
                                        <li>
                                            <a href="https://www.mcaleadsonline.com/terms-and-conditions/">Terms and Conditions</a>
                                        </li>
                                        <li>
                                            <a href="https://www.mcaleadsonline.com/privacy-policy/">Privacy Policy</a>
                                        </li>
                                    </ul>
                                    {/* <p>
                                        <a style={{paddingRight:"15px"}} href="https://twitter.com/yavaleads2biz" target="_blank" rel="noopener">
                                        <img style={{width:"18px"}} src="/images/twitter.png" alt="yava-icon" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/yava-leads-2-biz" target="_blank" rel="noopener">
                                        <img style={{width:"18px"}} src="/images/linkedin.png" alt="yava-icon" />
                                        </a>
                                    </p> */}
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className="footer_copy">
                <div className="container">
                    <div className="column one">
                        <div className="copyright"> © 2023 MCA LEADS ONLINE. All Rights Reserved.</div>
                        <ul className="social"></ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}