import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Axios from "axios";



export default function Sample() {

  let [enable, setEnable] = useState('');
  let [errorMessage, setErrorMessage] = useState('');
  let [plan, setPlan] = useState([]);
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');
  const navigate = useNavigate();
  let samplecheck = [];


    useEffect(() => {
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      var samplebusiness = localStorage.getItem('samplebusiness').split(',');
      console.log(samplebusiness,'samplebusiness');

      //getting the states value
      var statesArr = localStorage.getItem('statesCode').split(',');
      console.log(statesArr,'statesArr123445');

      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      let payload = {samplebusiness: samplebusiness, statesArr : statesArr};
      const headers = {
          'Access-Control-Allow-Origin': "*",
       }
        Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/samplebusiness`, payload, { headers: headers }).then(async (response) => {
        setPlan(response.data);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        let plans = response.data;
        plans.forEach((item, index) => {
          samplecheck.push(item.id);
        })
        console.log(samplecheck,'samplecheck')
      }).catch((err) => {
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setErrorMessage(err);
        console.log(errorMessage, "error");
      });


    }, []);

    let onSubmit = async (event) => {
      navigate("/customer");
    };


    // This following section will display the form that takes the input from the user.
    return (
      <div className="sample search-page">
        <div className={isLoadingcontainer}>
          <h3 className="page-title center">(MCA Leads - Sample Data)</h3>
          <div className={isLoading}>
          </div>
          <div className="sample-section">
            {plan.map((data, index) => {
              if(index < 3) {
                  return <div className="column_attr clearfix align_center sampledata">
                  <li><b>Business Legal Name:</b>
                  {data.business_name}
                  </li>
                  <li><b>Id:</b>
                  {/* {data.id} */}
                  **********
                  </li>
                  <li><b>Business Phone:</b>
                  {/* {data.business_phone} */}
                  **********
                  </li>
                  <li><b>Email:</b>
                  {/* {data.email} */}
                  **********
                  </li>
                  <li><b>Average Ledger Balance:</b>
                  {/* {data.ledger} */}
                  **********
                  </li>
                  <li><b>DBA:</b>
                  {/* {data.dba} */}
                  **********
                  </li>
                  <li><b>Gross Annual Revenue:</b>
                  {/* {data.gross} */}
                  **********
                  </li>
                  <li><b>Address:</b>
                  {/* {data.address} */}
                  **********
                  </li>
                  <li><b>State:</b>
                  {/* {data.state} */}
                  **********
                  </li>
                  <li><b>City:</b>
                  {/* {data.city} */}
                  **********
                  </li>
                  <li><b>zipcode:</b>
                  {/* {data.zipcode} */}
                  **********
                  </li>
                  </div>
              } else {
                return <div className="column_attr clearfix align_center sampledata">
                <li><b>Id:</b>**********</li>
                <li><b>Business Legal Name:</b>**********</li>
                <li><b>Business Phone:</b>**********</li>
                <li><b>Email:</b>**********</li>
                <li><b>Average Ledger Balance:</b>**********</li>
                <li><b>DBA:</b>**********</li>
                <li><b>Gross Annual Revenue:</b>**********</li>
                <li><b>Address:</b>**********</li>
                <li><b>State:</b>**********</li>
                <li><b>City:</b>**********</li>
                <li><b>zipcode:</b>**********</li>
                </div>
              }
              })}
          </div>
          <div className="sample-list-btn-out">
              <p>Subscribe to get more leads for your business</p>
              <input
                type="button"
                value="Subscribe"
                name= "Subscribe"
                className="btn btn-primary"
                onClick={onSubmit}
                />
            </div>
        </div>
      </div>
    );
  }
