import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { MultiSelect } from "react-multi-select-component";
import Axios from "axios";
// reactstrap components
import { InputGroupText } from "reactstrap";



export default function Customer() {

  const navigate = useNavigate();
  let [customer, setCustomer] = useState([]);
  let [errorMessage, setErrorMessage] = useState('');
  let [readonly, setReadonly] = useState(0);
  let [rtype, setRtype] = useState('none');
  let [referError, setReferError] = useState('');
  let [referErrorNew, setReferErrorNew] = useState('');
  let [rcodename, setRcodename] = useState('refer-section hide');
  let [rcodenameNew, setRcodenameNew] = useState('refer-section hide');

  let [refereroption] = useState([
    { label: "How did you hear about us", value: "none" },
    // { label: "Email", value: "email" },
    { label: "Facebook", value: "facebook" },
    { label: "Twitter", value: "twitter" },
    { label: "LinkedIn ", value: "linkedin" },
    { label: "Web Search", value: "websearch" },
    { label: "Others", value: "others" },
  ]);

  let [refereroptionNew] = useState([
    { label: "How did you hear about us", value: "none" },
    // { label: "Email", value: "email" },
    { label: "Facebook", value: "facebook" },
    { label: "Twitter", value: "twitter" },
    { label: "LinkedIn ", value: "linkedin" },
    { label: "Web Search", value: "websearch" },
    { label: "Others", value: "others" },
  ]);

  var promocode = '';
  var promocodeNew = '';


  //search liner states
  let [businessicon, setBusinessicon] = useState('milestone business-type active');
  let [addressicon, setAddressicon] = useState('milestone address-book active');
  let [revenueicon, setRevenueicon] = useState('milestone salary active');
  let [addressblock, setAddressblock] = useState('block-section hide');
  let [revenueblock, setRevenueblock] = useState('block-section hide');
  //search liner states

  let [alreadyRegisteredEmail, setAlreadyRegisteredEmail] = useState('');
  let [alreadyRegisteredEmailError, setAlreadyRegisteredEmailError] = useState('');
  let [customerdetail, setCustomerDetail] = useState({
    fname: '',
    lname: '',
    email: '',
    cemail: '',
    mobile: '',
    phone: '',
    address: '',
    state: '',
    city: '',
    business_name : '',
    business_website : '',
  });

  let [customerdetailNew, setCustomerDetailNew] = useState({
    fnameNew: '',
    lnameNew: '',
    emailNew: '',
    cemailNew: '',
    mobileNew: '',
    phoneNew: '',
    addressNew: '',
    stateNew: '',
    cityNew: '',
    business_nameNew : '',
    business_websiteNew : '',
  });
  let [userError, setUserError] = useState({
    firstnameError: '',
    lastnameError: '',
    phonenumberError: '',
    mobileError: '',
    stateError: '',
    cityError: '',
    addressError: '',
    emailError: '',
    cemailError: '',
    business_nameError: '',
    referDropdownError: '',
  });
  let [userErrorNew, setUserErrorNew] = useState({
    firstnameErrorNew: '',
    lastnameErrorNew: '',
    phonenumberErrorNew: '',
    mobileErrorNew: '',
    stateErrorNew: '',
    cityErrorNew: '',
    addressErrorNew: '',
    emailErrorNew: '',
    cemailErrorNew: '',
    business_nameErrorNew: '',
    referDropdownErrorNew: '',
  });
  let [searchleads, setSearchleads] = useState([]);
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingNew, setIsLoadingNew] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');
  let [otpnumber, setOtpnumber] = useState('');
  let [otpname, setOtpname] = useState('otp-section hide');
  let [displaymsg, setDisplaymsg] = useState('If you already have an account, fetch details');
  var searchquery = '';


  let changeRefer = (event) => {
    var rrcode = event.target.value;
    setRtype(event.target.value);
    if ((rrcode == 'facebook') || (rrcode == 'twitter') || (rrcode == 'linkedin') || (rrcode == 'websearch')) {
      setRcodename('refer-section show');
      setUserError({
        ...userError,
        referDropdownError : ''
      })
    } else {
      if(rrcode == 'others'){
        setRcodename('refer-section hide');
        setUserError({
          ...userError,
          referDropdownError : ''
        })
      }

    }

  }

  let changeReferNew = (event) => {
    var rrcode = event.target.value;
    console.log(rrcode, 'kljdkd]]]');
    setRtype(event.target.value);
    if ((rrcode == 'facebook') || (rrcode == 'twitter') || (rrcode == 'linkedin') || (rrcode == 'websearch')) {
      console.log('inside_if');
      setRcodenameNew('refer-section show');
    } else {
      console.log('inside_else');
      setRcodenameNew('refer-section hide');
    }

  }
  let updatecode = (event) => {
    promocode = event.target.value;
  };



  let updateotp = (event) => {
    setOtpnumber(event.target.value);
  };

  // changeInput
  let changeInput = (event) => {
    setCustomerDetail({
      ...customerdetail,
      [event.target.name]: event.target.value
    });
  };

  let changeAlreadyResiteredEmailInput = (event) => {
    setAlreadyRegisteredEmail(event.target.value);
  };

  // changeInput new
  let changeInputNew = (event) => {
    setCustomerDetailNew({
      ...customerdetailNew,
      [event.target.name]: event.target.value
    });
  };
  useEffect( () => {
    // To retrieve data
    console.log(otpnumber, 'otpnumbernew');
    // searchquery = JSON.parse(localStorage.getItem('searchleads'));
    // console.log(searchquery,'searchquery');
    setSearchleads(localStorage.getItem('searchLeads'));
  }, []);

  //for already registered customer
  let onSubmit = async (event) => {
    var result = 0;
    let tempuserError = {
      firstnameError: "",
      lastnameError: "",
      phonenumberError: "",
      mobileError: "",
      stateError: "",
      cityError: "",
      addressError: "",
      emailError: "",
      cemailError: "",
      business_nameError : "",
      referDropdownError : "",
    }
    try {
      event.preventDefault();
      if (customerdetail.fname == '') {
        result = 1;
        tempuserError.firstnameError = "Firstname field cannot be empty";
        console.log('inside_if_fname', result);
      } else {
        let regExp =
          /^[A-Za-z]+$/;
        if (!regExp.test(customerdetail.fname)) {
          result = 1;
          tempuserError.firstnameError = 'Enter proper Firstname using letters only';
          console.log('inside_else_one_fname', result);
        } else {
          // result = 0;
          setUserError({
            ...userError,
            firstnameError: "",
          });
          console.log('inside_else_two_fname', result);
        }
      }

      if (customerdetail.email == '') {
        result = 1;
        tempuserError.emailError = 'Email field cannot be empty';
      } else {
        // let regExp =
        //   /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        let regExp =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regExp.test(customerdetail.email)) {
          result = 1;
          tempuserError.emailError = 'Enter proper Email Id';
        } else {
          // result = 0;
          setUserError({
            ...userError,
            emailError: "",
          });
        }
      }
      if (customerdetail.cemail == '') {
        result = 1;
        tempuserError.cemailError = 'Email field cannot be empty';
      } else {
        let regExp =/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regExp.test(customerdetail.cemail)) {
          result = 1;
          tempuserError.cemailError = 'Enter proper  Email Id';
        } else if (customerdetail.email != customerdetail.cemail) {
          result = 1;
          tempuserError.cemailError = 'Email Id mismatch';
        } else {
          // result = 0;
          setUserError({
            ...userError,
            cemailError: "",
          });
        }
      }

        let regExp =
          /^[0-9\s*\b\+\-\(\)]+$/;
        if (!regExp.test(customerdetail.mobile)) {
          result = 1;
          tempuserError.mobileError = 'Enter proper Mobile number';
        } else {
          // result = 0;
          setUserError({
            ...userError,
            mobileError: "",
          });
        }

        if (customerdetail.business_name == '') {
          result = 1;
          tempuserError.business_nameError = "Business Name field cannot be empty";
          console.log('inside_if_business_name', result);
        } else {
          // let regExp =
          //   /^[A-Za-z]+$/;
          let regExp =
          /^[a-z][a-z\s]*$/;
          if (!regExp.test(customerdetail.business_name)) {
            result = 1;
            tempuserError.business_nameError = 'Enter proper Business Name using letters only';
            console.log('inside_else_business_nameError', result);
          } else {
            // result = 0;
            setUserError({
              ...userError,
              business_nameError: "",
            });
            console.log('inside_else_business_nameError', result);
          }
        }

        if(rtype == 'none'){
          result = 1;
          tempuserError.referDropdownError= 'Please choose any value';
        }


      if ((rtype == 'facebook') || (rtype == 'twitter')|| (rtype == 'linkedin') || (rtype == 'websearch')) {
        if (promocode != '') {

          let refererResult = 0;
          //promocode and its validations
          if(rtype == 'facebook'){
            let str = promocode;
              let firstTwoLetters = str.substring(0, 2); // start from index 0 and end before index 2
              console.log(firstTwoLetters); // "FB"
              if(firstTwoLetters != 'FB'){
                refererResult = 1;
                result = 1;
                setReferError('For Facebook referer code starts with FB');
              }else{
                setReferError('');
              }
          }
          if(rtype == 'twitter'){
            let str = promocode;
              let firstTwoLetters = str.substring(0, 2); // start from index 0 and end before index 2
              console.log(firstTwoLetters); // "FB"
              if(firstTwoLetters != 'TW'){
                refererResult = 1;
                result = 1;
                setReferError('For Twitter referer code starts with TW');
              }else{
                setReferError('');
              }
          }
          if(rtype == 'linkedin'){
            let str = promocode;
              let firstTwoLetters = str.substring(0, 2); // start from index 0 and end before index 2
              console.log(firstTwoLetters); // "FB"
              if(firstTwoLetters != 'LI'){
                refererResult = 1;
                result = 1;
                setReferError('For Linkedin referer code starts with LI');
              }else{
                setReferError('');
              }
          }
          if(rtype == 'websearch'){
            let str = promocode;
              let firstTwoLetters = str.substring(0, 2); // start from index 0 and end before index 2
              console.log(firstTwoLetters); // "FB"
              if(firstTwoLetters != 'WS'){
                refererResult = 1;
                result = 1;
                setReferError('For Websearch referer code starts with WS');
              }else{
                setReferError('');
              }
          }

          if(refererResult === 0){
            let payload = { email: customerdetail.email, promocode: promocode, promotype: rtype };
            const headers = {
              'Access-Control-Allow-Origin': "*",
            }
            await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/checkrefercode`, payload, { headers: headers }).then(async (response) => {
              localStorage.setItem('promocode', promocode);
              console.log(response.data, 'checkrefer')
              if (response.data == 1) {
                result = 0;
                setReferError('');
              } else {
                result = 1;
                setReferError('Invalid referer code. Please check');
              }
            }).catch((err) => {
              setIsLoadingcontainer('container');
              setIsLoading('loading-spinner hide');
              setErrorMessage(err);
            });
          }
        } else {
          result = 1;
          setReferError('Please enter valid referer code ...');
        }
      }else{
        console.log(rtype, 'rtypejddldeieiei000000000000');
        if(rtype == 'others'){
          setReferError('');
        }
      }

      console.log(result, 'result55555');

      if (result == 1) {
        setUserError({
          ...userError,
          firstnameError: tempuserError.firstnameError
            ? tempuserError.firstnameError
            : "",
          lastnameError: tempuserError.lastnameError
            ? tempuserError.lastnameError
            : "",
          cemailError: tempuserError.cemailError
            ? tempuserError.cemailError
            : "",
          phonenumberError: tempuserError.phonenumberError
            ? tempuserError.phonenumberError
            : "",
          mobileError: tempuserError.mobileError
            ? tempuserError.mobileError
            : "",
          emailError: tempuserError.emailError
            ? tempuserError.emailError
            : "",
          addressError: tempuserError.addressError
            ? tempuserError.addressError
            : "",
          stateError: tempuserError.stateError
            ? tempuserError.stateError
            : "",
          cityError: tempuserError.cityError
            ? tempuserError.cityError
            : "",
            business_nameError: tempuserError.business_nameError
            ? tempuserError.business_nameError
            : "",
            referDropdownError: tempuserError.referDropdownError
            ? tempuserError.referDropdownError
            : "",
        });
      }
       else if (result == 0) {

        setUserError({
          ...userError,
          firstnameError: tempuserError.firstnameError
            ? tempuserError.firstnameError
            : "",
          lastnameError: tempuserError.lastnameError
            ? tempuserError.lastnameError
            : "",
          cemailError: tempuserError.cemailError
            ? tempuserError.cemailError
            : "",
          phonenumberError: tempuserError.phonenumberError
            ? tempuserError.phonenumberError
            : "",
          mobileError: tempuserError.mobileError
            ? tempuserError.mobileError
            : "",
          emailError: tempuserError.emailError
            ? tempuserError.emailError
            : "",
          addressError: tempuserError.addressError
            ? tempuserError.addressError
            : "",
          stateError: tempuserError.stateError
            ? tempuserError.stateError
            : "",
          cityError: tempuserError.cityError
            ? tempuserError.cityError
            : "",
          business_nameError: tempuserError.business_nameError
            ? tempuserError.business_nameError
            : "",
            referDropdownError: tempuserError.referDropdownError
            ? tempuserError.referDropdownError
            : "",
        });
        let payload = { customerdetail: customerdetail, searchleads: searchleads, promotype: rtype, promocode: promocode };
        const headers = {
          'Access-Control-Allow-Origin': "*",
        }
        setIsLoadingcontainer('container spinner-container');
        setIsLoading('loading-spinner show');
        await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/addcustomer`, payload, { headers: headers }).then(async (response) => {
         localStorage.removeItem('customeremail');
          localStorage.setItem('customeremail', customerdetail.email);
          localStorage.setItem('isEmailMobile', response.data.isEmailMobile);
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          navigate("/leadoption");
        }).catch((err) => {
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          setErrorMessage(err);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  let checkOtp = async (event) => {
    var result = 0;
    let tempuserError = {
      emailError: "",
    }
    if (alreadyRegisteredEmail == '') {
      result = 1;
      tempuserError.emailError = 'Email field cannot be empty';
    } else {
      result = 0;
      tempuserError.emailError = '';
    }
    if (result == 1) {
      setAlreadyRegisteredEmail(tempuserError.emailError
        ? tempuserError.emailError
        : "");
    } else if (result == 0) {
      setAlreadyRegisteredEmail(tempuserError.emailError
        ? tempuserError.emailError
        : "");
      event.preventDefault();
      let payload = { email: alreadyRegisteredEmail, otp: otpnumber };
      const headers = {
        'Access-Control-Allow-Origin': "*",
      }
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/searchcustomer`, payload, { headers: headers }).then(async (response) => {
        let oldvalue = response.data;
        setCustomerDetail({
          ...customerdetail,
          email: oldvalue.Email ? oldvalue.Email : "",
          cemail: oldvalue.Email ? oldvalue.Email : "",
          mobile: oldvalue.Phone_no ? oldvalue.Phone_no : "",
          phone: oldvalue.Phone_no ? oldvalue.Phone_no : "",
          address: oldvalue.Address ? oldvalue.Address : "",
          state: oldvalue.State ? oldvalue.State : "",
          city: oldvalue.City ? oldvalue.City : "",
          country: "",
          fname: oldvalue.Customer_name ? oldvalue.Customer_name : "",

        });
        setReadonly(1);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setOtpname('otp-section hide');
        setDisplaymsg('You have been verified!');
      }).catch((err) => {
        setErrorMessage(err);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setOtpname('otp-section hide');
        setDisplaymsg('OTP mismatch or expired. please regenerate and check');
        console.log(errorMessage, "error");
      });
    }
  };
  let checkCustomer = async (event) => {
    var result = 0;
    let tempuserError = {
      emailError: "",
    }
    if (alreadyRegisteredEmail == '') {
      result = 1;
      tempuserError.emailError = 'Email field cannot be empty';
    } else {
      result = 0;
      tempuserError.emailError = '';
    }
    if (result == 1) {
      setAlreadyRegisteredEmailError(tempuserError.emailError
        ? tempuserError.emailError
        : "");
    } else if (result == 0) {
      setAlreadyRegisteredEmailError(tempuserError.emailError
        ? tempuserError.emailError
        : "");
      event.preventDefault();
      let payload = { email: alreadyRegisteredEmail };
      const headers = {
        'Access-Control-Allow-Origin': "*",
      }
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/sendotp`, payload, { headers: headers }).then(async (response) => {
        console.log(response.data, 'otp response');
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setOtpname('otp-section show');
        setDisplaymsg('Your received OTP please check your mail!');

      }).catch((err) => {
        setErrorMessage(err);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setOtpname('otp-section hide');
        setDisplaymsg('Email address not registered yet');
        console.log(errorMessage, "error");
      });
    }
  };

  // let prevnext = () => {
  //   navigate('/search');
  //   setRevenueblock('block-section hide');
  //   setAddressblock('block-section show');
  //   setBusinessicon('milestone business-type active');
  //   setAddressicon('milestone address-book active');
  //   setRevenueicon('milestone salary');
  // }


  return (
    <div className="customer-page">
      <div className={isLoadingcontainer}>
        {/* search fill liner */}
        <div className="search-liner">
            <div className={businessicon}>
              <div className="bar"></div>
              <div className="milestone-icon active">
                <InputGroupText>
                  <i className="far fa-address-card" />
                </InputGroupText>
                <p>Total Leads</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={addressicon}>
              <div className="bar active"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="far fa-address-book" />
                </InputGroupText>
                <p>State</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={revenueicon}>
              <div className="bar"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="fas fa-coins" />
                </InputGroupText>
                <p>Proposer</p>
              </div>
              <div className="bar"></div>
            </div>
          </div>
        <h3 className="page-title">Buyer Information</h3>

        <div className="row">
          <div className="col-md-12">
            <form>
            <div>
              <h3>Already Registered Customer</h3>
            </div>
            <div className="customer-form">
            <div className="block-half">
              <div className="form-group">
                <div>

                  <div className="search-detail">
                    <div className="input-group-merge input-group">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={alreadyRegisteredEmail}
                        name="email"
                        placeholder="Enter Email *"
                        onChange={changeAlreadyResiteredEmailInput}
                      />
                      <span className="display-msg">{displaymsg}</span>
                    </div>
                    <input
                      type="button"
                      value="Send CODE"
                      className="check btn"
                      onClick={checkCustomer}
                    />
                  </div>
                </div>

                <span className="err-msg">{alreadyRegisteredEmailError}</span>
              </div>

              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Confirm Email<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                  {/* <div className="input-group-merge input-group"> */}
                  <div className={otpname}>
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    value={otpnumber}
                    name="otp"
                    placeholder="OTP"
                    onChange={updateotp}
                  />
                  <input
                    type="button"
                    value="Verify OTP"
                    className="check btn"
                    onClick={checkOtp}
                  />
                {/* </div> */}
                </div>
                </div>
              </div>
            </div>
            </div>
            </form>
          </div>
          </div>


        <div style={{marginTop : '35px'}} className="row">
          <div className="col-md-12">
          <form onSubmit={onSubmit}>
          <div className={isLoading}>
          </div>

            <div>
              <h3>New Customer</h3>
            </div>
          <div className="customer-form">
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Email<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                    <div className="input-group-merge input-group">
                      <InputGroupText>
                        <i className="fas fa-envelope" />
                      </InputGroupText>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={customerdetail.email}
                        name="email"
                        placeholder="Enter Email *"
                        onChange={changeInput}

                      />
                    </div>

                </div>
                <span className="err-msg">{userError.emailError}</span>
              </div>

              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Confirm Email<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                  <div className="input-group-merge input-group">

                    <InputGroupText>
                      <i className="fas fa-envelope" />
                    </InputGroupText>
                    {readonly == 1 ?
                      <input
                        type="email"
                        className="form-control"
                        id="cemail"
                        readOnly
                        value={customerdetail.cemail}
                        name="cemail"
                        placeholder="Confirm Email *"
                        onChange={changeInput}

                      /> :
                      <input
                        type="email"
                        className="form-control"
                        id="cemail"
                        value={customerdetail.cemail}
                        name="cemail"
                        placeholder="Confirm Email *"
                        onChange={changeInput}

                      />
                    }
                  </div>
                </div>
                <span className="err-msg">{userError.cemailError}</span>
              </div>
            </div>
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">First Name<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      value={customerdetail.fname}
                      name="fname"
                      placeholder="First Name *"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.firstnameError}</span>
              </div>
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Last Name</label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      value={customerdetail.lname}
                      name="lname"
                      placeholder="Last Name"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.lastnameError}</span>
              </div>
            </div>
            {/* new fields business name and business website */}
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Mobile No.<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      maxLength={15}
                      value={customerdetail.business_name}
                      name="business_name"
                      placeholder="Business Name. *"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.business_nameError}</span>
              </div>
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Phone No.</label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      maxLength={15}
                      value={customerdetail.business_website}
                      name="business_website"
                      placeholder="Business Website."
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.business_websiteError}</span>
              </div>
            </div>
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Mobile No.<span style={{paddingLeft:"4px",color: "red"}}>*</span></label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      maxLength={15}
                      value={customerdetail.mobile}
                      name="mobile"
                      placeholder="Contact No. *"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.mobileError}</span>
              </div>
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Phone No.</label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-phone" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      maxLength={15}
                      value={customerdetail.phone}
                      name="phone"
                      placeholder="Phone No."
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.phonenumberError}</span>
              </div>
            </div>
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">Address</label> */}
                  <textarea
                    id="address"
                    className="form-control"
                    value={customerdetail.address}
                    name="address"
                    placeholder="Address"
                    onChange={changeInput}
                  />
                </div>
                <span className="err-msg">{userError.addressError}</span>
              </div>
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">State</label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-map-marker" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      value={customerdetail.state}
                      name="state"
                      placeholder="State"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.stateError}</span>
              </div>
            </div>
            <div className="block-half">
              <div className="form-group">
                <div>
                  {/* <label htmlFor="name">City</label> */}
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-map-marker" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={customerdetail.city}
                      name="city"
                      placeholder="City"
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <span className="err-msg">{userError.cityError}</span>
              </div>
              <div className="form-group">
                <div></div>
              </div>
            </div>
            <div className="block-half referer">
              <div className="form-group referer">
                <div>
                  {/* <label htmlFor="name">City</label> */}
                  <div className="input-group-merge input-group">
                    <select onChange={changeRefer}>
                      {refereroption.map(item => (
                        <option
                          key={item.value}
                          value={item.value}
                          className="form-control"
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>

                <span className="err-msg">{userError.referDropdownError}</span>
                  </div>
                </div>
              </div>
              <div className="form-group referer">
                <div className={rcodename}>
                  <input
                    type="text"
                    className="form-control"
                    id="refercode"
                    name="rcode"
                    placeholder="Refer Code"
                    onChange={updatecode}
                  />
                </div>
                <span className="err-msg">{referError}</span>
              </div>
            </div>
          </div>
          <div className="form-group btn-sec">
            <div></div>
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary"
            />
          </div>

        </form>
          </div>
        </div>
      </div>
    </div>
  );
}
