import React, { useEffect, useState } from "react";

export default function PageNotFound() {
 
    useEffect(async () => {

    }, []);



    // This following section will display the form that takes the input from the user.
    return (
      <div className="error-page">
        <div className="container">
          <h1>Page Not Found</h1>
          <div className="home-btn-div">
            <a href="/search" className="home-btn">Back Home</a>
          </div>
        </div>
      </div>
    );
  }
