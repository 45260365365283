import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Axios from "axios";



export default function Plans() {
  let [leadcount, setLeadCount] = useState([]);
  let [enable, setEnable] = useState('');
  let [errorMessage, setErrorMessage] = useState('');
  let [plan, setPlan] = useState([]);
  let [customplanprice, setCustomPlanPrice] = useState('');
  let [customplanid, setCustomPlanId] = useState('');
  let [resultcount, setResultcount] = useState(0);
  let [customers, setCustomers] = useState('');
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');


  const navigate = useNavigate();
  let customer = "";

    useEffect(async () => {
      customer = localStorage.getItem('customeremail');
      if(customer) {
        setCustomers(customer);
      }
      // To retrieve data
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      let dataURL = `${process.env.REACT_APP_YAVA_LEADS_URL}api/getplanpackage`;
      await Axios.get(dataURL).then(async (response) => {
        setPlan(response.data);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        let plans = response.data;
        plans.forEach((item, index) => {
          if (item.name == "custom") {
            setCustomPlanPrice(item.price);
            setCustomPlanId(item.id);
          }
        })
      }).catch((err) => {
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        setErrorMessage(err);
        console.log(errorMessage, "error");
      });

      if(localStorage.getItem('searchleads')) {
        var searchleadsarray = localStorage.getItem('searchleads').split(',');
        console.log(searchleadsarray.length,'lenfth')
        setResultcount(searchleadsarray.length);
        if ((searchleadsarray.length) < 250) {
          setEnable('column_attr clearfix align_center show');
          setLeadCount(searchleadsarray.length);

        } else {
          setEnable('column_attr clearfix align_center hide');
        }
      } else {
         setEnable('column_attr clearfix align_center hide');
      }

    }, []);


    // This function will handle the submission.
    let onSubmit = async (event) => {
        if (localStorage.getItem('customeremail')) {
          let planid = event.target.name;
          let payload = {planid: planid,customer: localStorage.getItem('customeremail')};
          const headers = {
            'Access-Control-Allow-Origin': "*",
          }
          setIsLoadingcontainer('container spinner-container');
          setIsLoading('loading-spinner show');
          await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/createorder`, payload, { headers: headers }).then(async (response) => {
            setIsLoadingcontainer('container');
            setIsLoading('loading-spinner hide');
            window.location.replace(response.data);
            // localStorage.clear();
            // navigate("/thankyou");
          }).catch((err) => {
            setIsLoadingcontainer('container');
            setIsLoading('loading-spinner hide');
            setErrorMessage(err);
          });
        } else {
          navigate("/search");
        }
    };
    let onSearch = async (event) => {
      navigate("/search");
    };


    // This following section will display the form that takes the input from the user.
    return (
      <div className="search-page">
        <div className={isLoadingcontainer}>
          <h3 className="page-title center">MCA Leads - Flexible Pricing</h3>
          <p className="page-subtitle">with our range of discounts, you can save by ordering the number of leads that work best for your  specific needs!</p>
          <div className="policy-details">
            <div>
              <img className="policy-logo" src="/images/icon01-plan-list-lg.png"></img>
              <p>100% Exclusive Leads (Up to 90 Days)</p>
            </div>
            <div>
              <img className="policy-logo" src="/images/icon04-plan-list-lg.png"></img>
              <p>No Bankruptcy</p>
            </div>
            <div>
              <img className="policy-logo" src="/images/icon02-plan-list-lg.png"></img>
              <p>Over $15,000 Bank Deposit/Month</p>
            </div>
            <div>
              <img className="policy-logo" src="/images/icon05-plan-list-lg.png"></img>
              <p>DNC & TCPA Compliant</p>
            </div>
            <div>
              <img className="policy-logo" src="/images/icon03-plan-list-lg.png"></img>
              <p>Over 1 year in Business</p>
            </div>
          </div>
          <div className={isLoading}>
          </div>
          <div className="plan-section">
            {plan.map((data, index) => {
              if (data.name != "custom") {
                  if(resultcount >= data.count ) {
                    return <div className="column_attr clearfix align_center">
                      <div className="plan-strip">{data.name}</div>
                      <h2>{data.name} Plan</h2>
                      <h4>Get Started with {data.count} Leads/ ${data.price} </h4>
                      <div className="plan-list-btn-out">
                        <input
                          type="button"
                          value="Buy Leads"
                          name={data.id}
                          className="btn btn-primary"
                          onClick={onSubmit}
                        />
                      </div>
                  </div>
                } else {
                  return <div className="column_attr clearfix align_center check">
                      <div className="plan-strip">{data.name}</div>
                      <h2>{data.name} Plan</h2>
                      <h4>Get Started with {data.count} Leads/ ${data.price} </h4>
                      <div className="plan-list-btn-out">
                        <input
                          type="button"
                          value="Buy Leads"
                          name={data.id}
                          className="btn btn-primary"
                          onClick={onSearch}
                        />
                      </div>
                  </div>
                }
              }
            })}
            <div className={enable} >
              <div className="plan-strip">Custom</div>
              <h2>Custom Plan</h2>
              {leadcount ? <h4>Get per lead at ${customplanprice} </h4> : <h4>Get per lead at Custom Price </h4>}
              {leadcount ? <p style={{ color: '#e0dede' }}>{leadcount} leads available for purchase based on your selection</p> : <p style={{ color: '#e0dede' }}>Get custom Leads from search page.</p> }
              <div className="plan-list-btn-out">
                <input
                  type="button"
                  value="Buy Leads"
                  name={customplanid}
                  className="btn btn-primary"
                  onClick={leadcount? onSubmit : onSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
