import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { MultiSelect } from "react-multi-select-component";
import Axios from "axios";
// reactstrap components
import { InputGroupText } from "reactstrap";


export default function SingleBusinessPage() {


    //search liner states
  let [businessicon, setBusinessicon] = useState('milestone business-type active');
  let [addressicon, setAddressicon] = useState('milestone address-book active');
  let [revenueicon, setRevenueicon] = useState('milestone salary active');
  let [addressblock, setAddressblock] = useState('block-section hide');
  let [revenueblock, setRevenueblock] = useState('block-section hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');


    return(
        <div className="customer-page">
      <div className={isLoadingcontainer}>
        {/* search fill liner */}
        <div className="search-liner">
            <div className={businessicon}>
              <div className="bar"></div>
              <div className="milestone-icon active">
                <InputGroupText>
                  <i className="far fa-address-card" />
                </InputGroupText>
                <p>Total Leads</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={addressicon}>
              <div className="bar active"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="far fa-address-book" />
                </InputGroupText>
                <p>State</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={revenueicon}>
              <div className="bar"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="fas fa-coins" />
                </InputGroupText>
                <p>Proposer</p>
              </div>
              <div className="bar"></div>
            </div>
        </div>
        <h3 className="page-title">Single Business Info</h3>
      </div>
    </div>
    )
}