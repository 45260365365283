import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Axios from "axios";



export default function Err() {
 
    useEffect(async () => {

    }, []);



    // This following section will display the form that takes the input from the user.
    return (
      <div className="error-page">
        <div className="container">
          <img className="error-logo"  src="/images/warning-sign.png"></img>
          <h3 className="page-title center">We're Sorry</h3>
          <p className="thank-content">Things don't appear to be working right now.</p>
          <div className="home-btn-div">
            <a href="/" className="home-btn">Back Home</a>
          </div>
        </div>
      </div>
    );
  }
