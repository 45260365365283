import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
// plugins styles from node_modules

// import "@fullcalendar/common/main.min.css";
// import "@fullcalendar/daygrid/main.min.css";
// import "react-notification-alert/dist/animate.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import "sweetalert2/dist/sweetalert2.min.css";
// import "select2/dist/css/select2.min.css";
// import "quill/dist/quill.core.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// // plugins styles downloaded
// import "assets/vendor/nucleo/css/nucleo.css";
// // core styles
// import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);