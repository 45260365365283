import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Axios from "axios";



export default function Success() {
 
    useEffect(async () => {

    }, []);



    // This following section will display the form that takes the input from the user.
    return (
      <div className="success-page">
        <h2> 
            Congratulations ! Successfully completed payment.  
        </h2> 
      </div>
    );
  }
