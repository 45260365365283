import React from "react";

// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";

// We import all the components we need in our app
import Header from "./components/header";
import Footer from "./components/footer";
import Sample from "./components/sample";
import Search from "./components/search";
import Customer from "./components/customer";
import Payment from "./components/payment";
import Thankyou from "./components/thankyou";
import Plans from "./components/plans";
import Success from "./components/success";
import Leadoption from "./components/leadoption";
import PageNotFound from './components/PageNotFound';

import Err from "./components/err";
import SingleBusinessPage from "./components/singleBusinessPage";


const App = () => {
  return (
    <div>
      <Header />
      <div>
        {/* <Search /> */}
      <Routes>
        <Route path="/search" element={<Search />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/sample" element={<Sample />} />
        <Route path="/success" element={<Success />} />
        <Route path="/err" element={<Err />} />
        <Route path="/leadoption" element={<Leadoption />} />
        <Route path="/singleBusinessPage" element={<SingleBusinessPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
