import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router";
import { MultiSelect } from "react-multi-select-component";
import Axios from "axios";
import { InputGroupText } from "reactstrap";
import SelectWrapper from './SelectWrapper';


export default function Search() {

  let [products, setProducts] = useState([]);
  let [states, setStates] = useState([]);
  let [statesErr, setStatesErr] = useState("");
  let [errorMessage, setErrorMessage] = useState('');

  let [noresult, setNoresult] = useState('');
  let [submitting, setSubmitting] = useState('');
  let [typerror, setTyperror] = useState('');
  let [businessblock, setBusinessblock] = useState('block-section show');
  let [addressblock, setAddressblock] = useState('block-section hide');
  let [revenueblock, setRevenueblock] = useState('block-section hide');
  let [businessicon, setBusinessicon] = useState('milestone business-type active');
  let [addressicon, setAddressicon] = useState('milestone address-book');
  let [revenueicon, setRevenueicon] = useState('milestone salary');
  let [selectvalue, setSelectvalue] = useState([]);
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');
  const samplebusiness = [];
  let [totalSampleBusiness, setTotalSampleBusiness] = useState([]);

  const [options, setOptions] = useState([]);
  //Checks if there are more pages to be loaded

  const [leadsCount, setLeadsCount] = useState('');
  const [leadsWithCount, setLeadsWithCount] = useState('');
  const [leadsLimitCount, setLeadsLimitCount] = useState('');
  const [showViewMore, setShowViewMore] = useState(false);



  let [selectedSearch, setSelectedSearch] = useState({
    // city: '',
    zip: '',
    // revfrom: 0,
    // revto: '',
    // ledfrom: 0,
    // ledto: '',
  });
  const navigate = useNavigate();


  let getBusinessType = async (page) => {
    let dataURL = `${process.env.REACT_APP_YAVA_LEADS_URL}api/businesstype`;
    await Axios.get(dataURL).then(async (response) => {
      setOptions(response.data);
      var temp = response.data;
    }).catch((err) => {
      setErrorMessage(err);
      console.log(errorMessage, "error");
    });
  }



  let getStates = async () => {
    let dataURL1 = `${process.env.REACT_APP_YAVA_LEADS_URL}api/states`;
    await Axios.get(dataURL1).then(async (response) => {
      setStates(response.data);
    }).catch((err) => {
      setErrorMessage(err);
      console.log(errorMessage, "error");
    });
  }

  //get all leads count api
  let getAllLeadsCount = async (page) => {
    // setNextPageLoading(true);
    let dataURL = `${process.env.REACT_APP_YAVA_LEADS_URL}api/getAllLeadsCount`;
    await Axios.get(dataURL).then(async (response) => {

      setLeadsCount(response.data.totalLeadsCounts);
      var temp = response.data;
    }).catch((err) => {
      setErrorMessage(err);
      console.log(errorMessage, "error");
    });
  }

  useEffect( () => {
     getBusinessType()
     getStates()
     getAllLeadsCount()
  }, []);

  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState([]);

  let selectbusiness = (event) => {
    setSelected(event);
    setSelectvalue(event);
  }

  let nextclick = (event) => {
    // let nn = selected;
    // nn.map((item) => {
    //    console.log(item.label,'label');
    // });

    // if (selected == '') {
    //   setTyperror('Please select atleast one Business type');
    // } else {
      setTyperror('');
      setBusinessblock('block-section hide');
      setAddressblock('block-section show');

      setAddressicon('milestone address-book active');
      setRevenueicon('milestone salary');
    // }
  };

  let nextclick2 = async(event) => {

    let payload;
    if(selected1.length > 0){
      setStatesErr("")
       payload = {
      // btype: selected,
      states: selected1,
      // others: selectedSearch
    };

    const headers = {
      'Access-Control-Allow-Origin': "*",
    }

    let statesCodeArr = [];
    await selected1.map((item) => {
      statesCodeArr.push(item.value);
    });

    // if(localStorage.getItem('statesCode')){
    //   localStorage.removeItem('statesCode');
    // }else{
      localStorage.setItem('statesCode', statesCodeArr);
    // }


    await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/statesfilter`, payload,
    { headers: headers }).then((res) => {
      if(res.data == 1){
        setStatesErr("");
        getLeadsWithCount();
        // setAddressblock('block-section hide');
        // setRevenueblock('block-section show');
        // setRevenueicon('milestone salary active');
      }else{
        setStatesErr("No Records found");
      }

    }).catch((err) => {
      console.log(err);
    })
    }else{
      setStatesErr("Please select any state");
    }
  };

  let getLeadsWithCount = async (leadslimit = 30) => {
    setIsLoading('loading-spinner show');

    let statesArr = localStorage.getItem('statesCode');

    console.log(statesArr, 'statesArrjlkdjkdoiie');

    let dataURL = `${process.env.REACT_APP_YAVA_LEADS_URL}api/getLeadsWithCount?limit=${leadslimit}&statesArr=${statesArr}`;
    await Axios.get(dataURL).then(async (response) => {
      setLeadsWithCount(response.data);
      let showViewMoreCount = response.data[0].total_count;

      if(leadslimit > 35){
        setShowViewMore(false);
      }else{
        if(showViewMoreCount > 30){
        setShowViewMore(true);
      }else{
        setShowViewMore(false);
      }
      }

      response.data.forEach((sample, index) => {
        if(index <= 10) {
          samplebusiness.push(sample.business_id);
        }
    })
    // localStorage.removeItem('samplebusiness');
    // localStorage.setItem('samplebusiness', samplebusiness);
    // console.log(samplebusiness);
      setTotalSampleBusiness(samplebusiness);
      setIsLoading('loading-spinner hide');
      setAddressblock('block-section hide');
      setRevenueblock('block-section show');
      setRevenueicon('milestone salary active');
      setLeadsLimitCount(response.data.length);
      var temp = response.data;
    }).catch((err) => {
      setErrorMessage(err);
      console.log(errorMessage, "error");
    });
  }


  let prev = (event) => {
    setAddressblock('block-section hide');
    setBusinessblock('block-section show');
    setBusinessicon('milestone business-type active');
    setAddressicon('milestone address-book');
    setRevenueicon('milestone salary');
    setSelected1([]);
  }
  let prevnext = (event) => {
    setRevenueblock('block-section hide');
    setAddressblock('block-section show');
    setBusinessicon('milestone business-type active');
    setAddressicon('milestone address-book active');
    setRevenueicon('milestone salary');
  }

  // changeInput
  let changeInput = (event) => {
    setSelectedSearch({
      ...selectedSearch,
      [event.target.name]: event.target.value
    });
  };
  // let revento = (event) => {
  //   var tt = parseInt(selectedSearch.revfrom);
  //   var temp = parseInt(event.target.value);
  //   setSelectedSearch({
  //     ...selectedSearch,
  //     [event.target.name]: event.target.value
  //   });
  //   if ((temp != 0) || (temp >= tt)) {
  //     setEmsgs("");
  //   }
  //   else {
  //     setEmsgs("Enter amount less than Revenue From field");
  //   }
  // };
  // let ledsto = (event) => {
  //   var tt = parseInt(selectedSearch.ledfrom);
  //   var temp = parseInt(event.target.value);
  //   setSelectedSearch({
  //     ...selectedSearch,
  //     [event.target.name]: event.target.value
  //   });
  //   if ((temp != 0) || (temp >= tt)) {
  //     setEmsgs3("");
  //   }
  //   else {
  //     setEmsgs3("Enter amount less than Ledger From field");
  //   }
  // };


  // This function will handle the submission.
  let onSubmit = async (event) => {
    var result = 0;
    event.preventDefault();
    if (selected == '') {
      result = 1;
      setTyperror('Please select atleast one Business type');
    } else {
      result = 0;
    }
    let payload = { btype: selected,
      states: selected1,
      // others: selectedSearch
    };
    const headers = {
      'Access-Control-Allow-Origin': "*",
    }
    if (result == 0) {
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/searchfilter`, payload, { headers: headers }).then(async (response) => {
        if (response.data == 0) {
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          setNoresult('No Result Found ... Please Try Different Search Value !');
          // setSubmitting(1);
        } else {
          setNoresult('');
          const searchleads = response.data;
          localStorage.removeItem('searchquery');
          localStorage.setItem('searchquery', JSON.stringify(searchleads));
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          // navigate("/customer");
          navigate("/sample");

        }
      }).catch((err) => {
        setErrorMessage(err);
        console.log(errorMessage, "error");

      });
    }
  };

  let loadingFunction = async () => {

    setIsLoading('loading-spinner show');

       setTimeout(() => {
        setIsLoading('loading-spinner hide');
      }, 1000)


  }

  //view more function

  let viewMoreFunc = () => {
    getLeadsWithCount(leadsCount);
  }

  let totalLeadsSearch = () => {
    // navigate("/customer");
    localStorage.removeItem('samplebusiness');
    localStorage.setItem('samplebusiness', totalSampleBusiness);
    localStorage.setItem('totalLeadsSearchClicked', 1);

    //getting the local storage state array
    let statesArr = localStorage.getItem('statesCode').split(',');

    let searchLeadsArr = [{ "State": {"$in" : statesArr} ,  "Is_flagged": 'null' }];

    let stringifySearchLeads = JSON.stringify(searchLeadsArr);

    localStorage.setItem('searchLeads', stringifySearchLeads);
    console.log(samplebusiness);
    navigate("/sample");
  }

  let handleParticularBusinesstype = (business_id) => {
    console.log(business_id, 'business_id');
    setTotalSampleBusiness([]);
    localStorage.removeItem('samplebusiness');

    setTotalSampleBusiness([business_id]);
    localStorage.setItem('samplebusiness', [business_id]);
    localStorage.setItem('totalLeadsSearchClicked', 0);

    //getting the local storage state array
    let statesArr = localStorage.getItem('statesCode').split(',');

    console.log(statesArr, 'statesArrkjdjdlk');

    let searchLeadsArr = [{ "State": {"$in" : statesArr} ,  "Is_flagged": 'null' , "business_id": {"$in" : [business_id]} }];

    console.log(searchLeadsArr, 'searchLeadsArrvvvv');

    let stringifySearchLeads = JSON.stringify(searchLeadsArr);

    localStorage.setItem('searchLeads', stringifySearchLeads);


    console.log(totalSampleBusiness);
    navigate("/sample");

  }


  // This following section will display the form that takes the input from the user.
  return (
    <div className="search-page">
      <div className={isLoadingcontainer}>
        <h3 className="page-title">Search Leads</h3>

        <form onSubmit={onSubmit}>
          <div className={isLoading}>
          </div>
          <div className="search-liner">
            <div className={businessicon}>
              <div className="bar"></div>
              <div className="milestone-icon active">
                <InputGroupText>
                  <i className="far fa-address-card" />
                </InputGroupText>
                <p>Leads</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={addressicon}>
              <div className="bar active"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="far fa-address-book" />
                </InputGroupText>
                <p>State</p>
              </div>
              <div className="bar"></div>
            </div>
            <div className={revenueicon}>
              <div className="bar"></div>
              <div className="milestone-icon">
                <InputGroupText>
                  <i className="fas fa-coins" />
                </InputGroupText>
                <p>Buyer</p>
              </div>
              <div className="bar"></div>
            </div>
          </div>
          <div className="search-form">
            {/* bussiness block */}
            <div className={businessblock}>
              <div className="full-cart">
                <h1>MCA Leads Sample</h1>
                <div class="sample-card">
                  <div className="cart-part-half display-half">
                      <li><b>Lead Id</b>  &nbsp;52303</li>
                      <li><b>Business Legal Name:</b>  &nbsp;Dominguez f*****</li>
                      <li><b>Doing Business As:</b> &nbsp;More than $5,000</li>
                      <li><b>Business Phone:</b> &nbsp;***-***-3451</li>
                      <li><b>Email:</b> &nbsp;*******@Gmail.com</li>
                      <li><b>Gross Annual Revenue:</b> &nbsp;277,430</li>
                      <li><b>State Of Incorporation:</b>  &nbsp;TX</li>
                      <li><b>City:</b>  &nbsp;Fabens</li>
                      <li><b>State:</b>  &nbsp;TX</li>
                  </div>
                  <div className="cart-part-half second-card display-half">
                      <li><b>Zipcode:</b> &nbsp;79838</li>
                      <li><b>Sic Code:</b> &nbsp;***</li>
                      <li><b>Owner First name:</b> &nbsp;An******</li>
                      <li><b>Owner Last name:</b> &nbsp;Do******</li>
                      <li><b>Owner Address:</b>  &nbsp;170*******</li>
                      <li><b>Owner City:</b>  &nbsp;Fa*******</li>
                      <li><b>Owner State:</b>  &nbsp;TX </li>
                      <li><b>Owner Zip:</b>  &nbsp;79838</li>
                  </div>
                </div>
              </div>
              <div className="block-split" style={{ display: "block" }}>
                <div
                 className="form-group business">
                  {/* <label htmlFor="name">We have One Lack Ten Thousands <span>+</span> Leads</label> */}
                  {/* <p className="total-leads-count">We have One Lakh Ten Thousands <span className="plus_sign">Plus</span> Leads</p> */}
                  <p className="total-leads-count">We have an extensive number of <span className="plus_sign">Quality Leads</span> </p>
                  <p className="total-leads-count-content">* The above is a graphic representation of a lead with all the details. Based on your selection/price, some of the leads may have limited information.</p>
                </div>
                {/* <p className="note-label"><span>* </span>At Least select one business types.</p> */}
                <div className="business-select-value">
                {selected.map((data, index) => {
                    return <span className="card-box">
                          <span>{data.label}</span>
                      </span>
                 })}
                </div>
                <div className="form-group btn-sec next">
                  <input
                    type="button"
                    value="NEXT"
                    className="btn btn-primary"
                    onClick={nextclick}
                  />
                </div>
              </div>
            </div>
            {/* address block */}
            <div className={addressblock}>
              <div className="block-split address">
                <div className="form-group">
                  <label htmlFor="name">Choose Leads from particular State</label>
                  <MultiSelect
                    options={states}
                    value={selected1}
                    placeholder="Select a game"
                    onChange={setSelected1}
                    labelledBy={selected1}
                  />
                </div>
                <p className="error-result" style={{ color: "red", paddingTop : '8px' }}>{statesErr}</p>
                {/* <div className="form-group">
                  <label htmlFor="name">City</label>
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-map-marker" />
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={selectedSearch.city}
                      name="city"
                      placeholder="Enter City"
                      onChange={changeInput}
                    />
                  </div>
                </div> */}
                {/* <div className="form-group">
                  <label htmlFor="position">Zip</label>
                  <div className="input-group-merge input-group">
                    <InputGroupText>
                      <i className="fas fa-map-pin"></i>
                    </InputGroupText>
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      name="zip"
                      placeholder="Zip"
                      value={selectedSearch.zip}
                      onChange={changeInput}
                    />
                  </div>
                </div> */}
              </div>
              <div className="form-group btn-sec next address">
                <input
                  type="button"
                  value="PREVIOUS"
                  className="btn btn-primary"
                  onClick={prev}
                />
                <input
                  type="button"
                  value="NEXT"
                  className="btn btn-primary"
                  onClick={nextclick2}
                />
                {/* <input
                  type="submit"
                  value="SEARCH"
                  className="btn btn-primary"
                  disabled={submitting}
                /> */}
              </div>
            </div>
            {/* revenue block */}
            <div className={revenueblock}>
              <div >
                <div className="form-group">
                  <label className="heading-business-types" htmlFor="position">Business Leads</label>
                  <div className="parent-business-types">
                    {
                      leadsWithCount && leadsWithCount.length > 0 && leadsWithCount.map((leads) => {
                        return(
                          <div className="each-business-types">
                            <div style={{cursor : "pointer"}} onClick={() => handleParticularBusinesstype(leads.business_id)}><span className="each-business-names">{leads.business_name}{"  "}</span> ({(leads.count)})</div>
                            {/* <div className="each-business-count">({(leads.count)})</div> */}
                        </div>
                        )
                      })
                    }
                  </div>
                  {
                    (showViewMore == true) && (
                      <div className="business-leads-button-parent">
                        <button onClick={viewMoreFunc} className="business-leads-button">View More</button>
                      </div>
                    )
                  }

                </div>
              </div>
              <div className="form-group btn-sec address">
                <input
                  type="button"
                  value="PREVIOUS"
                  className="btn btn-primary"
                  onClick={prevnext}
                />
                <input
                  type="submit"
                  value="SEARCH"
                  className="btn btn-primary"
                  disabled={submitting}
                  onClick={totalLeadsSearch}
                />
              </div>
            </div>
            <p className="error-result" style={{ color: "red" }}>{noresult}</p>
          </div>
        </form>
      </div >
    </div >
  );
}
