import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import Axios from "axios";
import { CSVLink } from 'react-csv';



export default function Thankyou() {

  const queryParameters = new URLSearchParams(window.location.search)
  const orderId = queryParameters.get("orderId")

  const navigate = useNavigate();
  useEffect(async () => {
    localStorage.clear();
  }, []);


  // This function will handle the submission.
  let onSubmit = async (event) => {

  };

  // This following section will display the form that takes the input from the user.
  return (
      <div className="thankyou-page">
        <div className="container">
          <img className="thank-logo"  src="/images/email.png"></img>
          <h3 className="page-title center">MCA Leads Online</h3>
          <p className="greeting">Thank you for placing {orderId} order.</p>
          <p className="thank-content">We've sent the purchased leads to your email.</p>
          <div>
            <a href="/" className="home-btn">Back Home</a>
          </div>
        </div>
      </div>
  );
}
