import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import {Link} from 'react-router-dom';
import Axios from "axios";
// reactstrap components
import {InputGroupText} from "reactstrap";



export default function Leadoption() {

  const navigate = useNavigate();
  let [errorMessage , setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');
  let [optiontype, setOptiontype] = useState();
  let [err, setErr] = useState('');
  let [customers, setCustomers] = useState('');
  let [isEmailMobile, setIsEmailMobile] = useState(null);
  let [leadTypeOne, setLeadTypeOne] = useState('');
  let [leadTypeTwo, setLeadTypeTwo] = useState('');
  let [leadTypeThree, setLeadTypeThree] = useState('');


  useEffect( () => {
    // To retrieve data
    let customer = "";
    customer = localStorage.getItem('customeremail');
    setCustomers(customer);
    let isEmailMobile = localStorage.getItem('isEmailMobile');
    setIsEmailMobile(isEmailMobile);


    // To retrieve data
    // setIsLoadingcontainer('container spinner-container');
    // setIsLoading('loading-spinner show');
    var leadoption = ['1','2','3'];

    let searchleadscount = [];
     let searchleadscounttemp = localStorage.getItem('searchLeads');
     console.log('outside_searchleadcount', searchleadscounttemp);
     if(searchleadscounttemp){
      console.log('inside_if_searchleadscounttemp', searchleadscounttemp);
      searchleadscount = searchleadscounttemp.split(',')
      console.log('inside_if_searchleadscount', searchleadscount);
     }else{
      console.log('inside_else_searchleadscounttemp');
     }

    let payload = {leadscount: searchleadscount,leadoption: leadoption, email:localStorage.getItem('customeremail') };
      const headers = {
        'Access-Control-Allow-Origin': "*",
      }
      // setIsLoadingcontainer('container spinner-container');
      // setIsLoading('loading-spinner show');
    //    Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/get_radio_button_package`, payload, { headers: headers }).then(async (response) => {
    //     console.log(response.data.plandetail , 'response_responsebbbb___plandetail');

    //   setIsLoadingcontainer('container');
    //   setIsLoading('loading-spinner hide');

    //   await response.data.plandetail.map((plan) => {
    //       if(plan.lead_option == '1'){
    //         setLeadTypeOne(`Leads without Email or Phone Number - $${plan.totalPrice}`);
    //       }
    //       if(plan.lead_option == '2'){
    //         setLeadTypeTwo(`Leads with Email and Phone Number - $${plan.totalPrice}`);
    //       }
    //       if(plan.lead_option == '3'){
    //         setLeadTypeThree(`Leads without Email and Phone Number - $${plan.totalPrice}`);
    //       }
    //   })
    // }).catch((err) => {
    //   setErrorMessage(err);
    //   console.log(errorMessage, "error");
    // });


  }, []);

  let onSubmit = async (event) => {
    if(optiontype) {
      setErr('');
      if(localStorage.getItem('customeremail')) {
        let payload = {optiontype: optiontype,customer: localStorage.getItem('customeremail')};
        const headers = {
          'Access-Control-Allow-Origin': "*",
        }
        setIsLoadingcontainer('container spinner-container');
        setIsLoading('loading-spinner show');
        await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/setpackagetype`, payload, { headers: headers }).then(async (response) => {
          localStorage.removeItem('leadoption');
          localStorage.setItem('leadoption', optiontype);
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          console.log(response.data,'response data')
          if(response.data == '0') {
            setErr('This option not available now. Please choose another option.');
          } else {
            localStorage.removeItem('searchleads');
            localStorage.setItem('searchleads', response.data);
            navigate("/payment");
          }

        }).catch((err) => {
            setIsLoadingcontainer('container');
            setIsLoading('loading-spinner hide');
            setErrorMessage(err);
        });
      } else {
        setErr('Please Fill Proposer Information Form details');
      }
    } else {
      setErr('Please select atleast one option');
    }
  }
  let onChangeValue = async (event) => {
    setOptiontype(event.target.value);
    setErr('');
  }

  return (
    <div className="lead-page">
      <div className={isLoadingcontainer}>
        <h3 className="page-title">Select type of leads information</h3>
        <form>
         <div className={isLoading}>
          </div>
          {/* old condition without price */}
          {
            isEmailMobile && isEmailMobile.length > 0 ? (<div className="lead-form" onChange={onChangeValue}>

            {
              isEmailMobile && isEmailMobile.length > 0 && isEmailMobile.includes('1') &&
              <>
               <input type="radio" value="2" name="emailoption" /> Leads with Email and Phone Number - Get per lead at $1
              </>

            }
            <p className="clear"></p>
            {
              isEmailMobile && isEmailMobile.length > 0 && isEmailMobile.includes('2') &&
              <>
                <input type="radio" value="1" name="emailoption" /> Leads without Email or Phone Number - Get per lead at 50 cents
              </>

            }

            <p className="clear"></p>
            {
              isEmailMobile && isEmailMobile.length > 0 && isEmailMobile.includes('3') &&
              <>
              <input type="radio" value="3" name="emailoption" /> Leads without Email and Phone Number - Get per lead at 30 cents
              </>
            }

          </div>) : (
            <>
              <div style={{fontSize: '22px',
    textAlign: 'center'}} className="err-msg">You recently purchased those leads so now currently unavailable.Please try different leads.</div>
            </>
          )
          }
           {/* old condition without price */}

          {/* new condition based leads with price */}
          {/* {
            isEmailMobile && isEmailMobile.length > 0 ? (<div className="lead-form" onChange={onChangeValue}>

            {
              leadTypeTwo && leadTypeTwo !== '' &&
              <>
               <input type="radio" value="2" name="emailoption" /> {leadTypeTwo}

              </>

            }
            <p className="clear"></p>
            {
              leadTypeOne && leadTypeOne !== '' &&
              <>
                <input type="radio" value="3" name="emailoption" /> Leads without Email and Phone Number
              </>

            }

            <p className="clear"></p>
            {
              leadTypeThree && leadTypeThree !== '' &&
              <>
              <input type="radio" value="3" name="emailoption" /> {leadTypeThree}
              </>
            }

          </div>) : (
            <>
              <div style={{fontSize: '22px',
    textAlign: 'center'}} className="err-msg">Please try again!</div>
            </>
          )
          } */}
          {/* new condition based leads with price */}

          {
              isEmailMobile && isEmailMobile.length > 0 ? (
                <div className="form-group btn-sec">
                  <div className="err-msg">{err}</div>
                  <input
                    type="button"
                    value="Next"
                    className="btn btn-primary"
                    onClick={onSubmit}
                  />
              </div>
              ) : ( <div style={{textAlign: 'center'}} className="form-group btn-sec">
                 <div className="err-msg">{err}</div>
              <Link
                to={'/search'}
                value="Home"
                className="btn btn-primary"
              >Search</Link>
            </div>)}

        </form>
      </div>
    </div>
  );
}
