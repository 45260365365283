import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Axios from "axios";



export default function Payment() {
  let [leadcount, setLeadCount] = useState([]);
  let [enable, setEnable] = useState('column_attr clearfix align_center hide');
  let [errorMessage, setErrorMessage] = useState('');
  let [plan, setPlan] = useState([]);
  let [customplanprice, setCustomPlanPrice] = useState('');
  let [totalPrice, setTotalPrice] = useState('');
  let [customplanid, setCustomPlanId] = useState('');
  let [resultcount, setResultcount] = useState();
  const [isLoading, setIsLoading] = useState('loading-spinner hide');
  const [isLoadingcontainer, setIsLoadingcontainer] = useState('container');
  const [leadOption, setLeadOption] = useState('');

  const navigate = useNavigate();
  let customer = "";



    useEffect( () => {
      // To retrieve data
      setIsLoadingcontainer('container spinner-container');
      setIsLoading('loading-spinner show');
      var leadoption = '' ;
      if(localStorage.getItem('leadoption')) {
        leadoption = localStorage.getItem('leadoption');
      } else {
        leadoption = '1';
      }
      setLeadOption(leadoption);

      var searchleadscount = localStorage.getItem('searchleads').split(',');

      let payload = {leadscount: searchleadscount,leadoption: leadoption, email:localStorage.getItem('customeremail') };
        const headers = {
          'Access-Control-Allow-Origin': "*",
        }
        setIsLoadingcontainer('container spinner-container');
        setIsLoading('loading-spinner show');
         Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/getpackage`, payload, { headers: headers }).then(async (response) => {
        // if(leadoption == 2) {
        //   localStorage.removeItem('searchleads');
        //   localStorage.setItem('searchleads', response.data.newid);
        // }
        setPlan(response.data.plandetail);
        setIsLoadingcontainer('container');
        setIsLoading('loading-spinner hide');
        let plans = response.data.plandetail;
        plans.forEach((item, index) => {
          if (item.name == "custom") {
            setCustomPlanPrice(item.price);
            setTotalPrice(item.totalPrice);
            console.log(item.price, 'balabalalaabalalalal');
            setCustomPlanId(item.id);
            var searchleadsarray = localStorage.getItem('searchleads').split(',');
            if(searchleadsarray != '') {
              if(item.id == 5) {
                setEnable('column_attr clearfix align_center show');
                setLeadCount(searchleadsarray.length);
              }
              if(item.id == 8) {
                setEnable('column_attr clearfix align_center show');
                setLeadCount(searchleadsarray.length);
              }
              if(item.id == 9) {
                setEnable('column_attr clearfix align_center show');
                setLeadCount(searchleadsarray.length);
              }
            }
          }
        })
      }).catch((err) => {
        setErrorMessage(err);
        console.log(errorMessage, "error");
      });

      customer = localStorage.getItem('customeremail');

      var searchleadsarray = localStorage.getItem('searchleads').split(',');
      if(searchleadsarray == ''){
        setResultcount(0);
      } else {
        console.log(searchleadsarray.length,'lenfth');
        setResultcount(searchleadsarray.length);
      }


    }, []);


    // This function will handle the submission.
    let onSubmit = async (event) => {
        let planid = event.target.name;
        let payload = {planid: planid,customer: localStorage.getItem('customeremail'), promocode: localStorage.getItem('promocode')};
        const headers = {
          'Access-Control-Allow-Origin': "*",
        }
        setIsLoadingcontainer('container spinner-container');
        setIsLoading('loading-spinner show');
        await Axios.post(`${process.env.REACT_APP_YAVA_LEADS_URL}api/createorder`, payload, { headers: headers }).then(async (response) => {
          setIsLoadingcontainer('container');
          setIsLoading('loading-spinner hide');
          window.location.replace(response.data);
          // localStorage.clear();
          // navigate("/thankyou");
        }).catch((err) => {
            setIsLoadingcontainer('container');
            setIsLoading('loading-spinner hide');
            setErrorMessage(err);
        });
    };


    // This following section will display the form that takes the input from the user.
    return (
      <div className="search-page">
         <div className={isLoadingcontainer}>
          {/* <h3 className="page-title center">MCA Leads pricing package</h3> */}
          <h3 className="page-title center">Your Purchase Details</h3>
          {/* <p className="page-subtitle">Please select the custom packages!</p> */}
          <div className={isLoading}>
          </div>
          <div className="plan-section">
            <div className={enable} >
              <div className="plan-strip">Custom</div>
              <h2>
                {
                  leadOption == '1' && (<>Leads without Email or Phone</>)
                }
                {
                  leadOption == '2' && (<>Leads with Email and Phone</>)
                }
                {
                  leadOption == '3' && (<>Leads without Email and Phone</>)
                }
              </h2>
              {
                customplanprice == `${process.env.REACT_APP_ONE_DOLLAR}` && <h4>Get per lead at ${customplanprice}</h4>
              }
              {
                customplanprice == `${process.env.REACT_APP_FIFTY_CENTS}` && <h4>Get per lead at 50 cents </h4>
              }
              {
                customplanprice == `${process.env.REACT_APP_THIRTY_CENTS}` && <h4>Get per lead at 30 cents </h4>
              }
              <p style={{ color: '#e0dede' }}>Currently {leadcount} leads available for purchase based on your selection</p>
              <p style={{ color: '#e0dede' }}>Total Price {"  "} ${totalPrice}</p>
              <div className="plan-list-btn-out">
                <input
                  type="button"
                  value="Buy Leads"
                  name={customplanid}
                  className="btn btn-primary"
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
